import Honeybadger from 'honeybadger-js';
import jQuery from 'jquery';
import 'bootstrap';
import 'fonts/index';
import VMasker from 'vanilla-masker';

window.$ = jQuery;
window.jQuery = jQuery;
window.Honeybadger = Honeybadger;
window.VMasker = VMasker;
